form .bg-success,
form .bg-danger {
  padding: 8px 15px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.fa-btn {
  margin-right: 6px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.fileinput-button {
  display: block;
  overflow: hidden;
  position: relative;
}
.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}
.create-progress {
  margin: 0;
  height: 0;
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
}
.create-progress.active {
  height: 5px;
}
.dropzone {
  width: 100%;
  height: 0;
  border: none;
  -moz-border-radius-bottomleft: 15px;
  border-bottom-left-radius: 15px;
  -moz-border-radius-bottomright: 15px;
  border-bottom-right-radius: 15px;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}
.dropzone.in {
  height: 100px;
  border: 3px #CCCCCC dashed;
  border-top: none;
}
.dropzone.hover {
  background: #e5e5e5;
}
.dropzone.fade {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  opacity: 1;
}
.handle {
  cursor: move;
}
.lang-tab .loading {
  display: none;
}
.edit-menu-form {
  display: none;
}
.color {
  display: inline-block;
  width: 100px;
  height: 20px;
}
.block-sortable,
.block-placeholder {
  float: left;
  width: 187px;
  padding: 0 10px 10px;
  margin: 1px;
  overflow: hidden;
  height: 187px;
  background: rgba(0, 0, 0, 0.2);
  cursor: move;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mb1 {
  margin-bottom: 10px;
}
.clearfix,
.clr {
  clear: both;
}

/*# sourceMappingURL=app-admin.css.map */
